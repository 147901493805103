import React, { useState, useEffect } from 'react';
import { Button, Steps, theme } from 'antd';
import Card from '@/components/Card';
import { enrollService } from '@/services/authService';
import Loader from '@/components/Loader';
import { AppDispatch, AppState } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { getValueLocalStorage } from '@/utils/helper';
import { useTwoFa } from '@/hooks/use2Fa';
import { getTwoFaPreferences } from '@/services/commonService';
import { IPreference } from '@/store/auth/authInterface';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import LinkAccount from './LinkAccount';
import Choose2FaMethod from './Choose2FaMethod';
import OverView from './OverView';

function TwoFactorSetUp() {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const phone: string = getValueLocalStorage('phoneNumber') || '';
  const {
    twoFaState,
    phoneState,
    handleTwoFaMethodChange,
    handleChangePhoneNumber,
    handleIsChangePhoneNumber,
    handle2FaIsLoading,
    handleQRData,
  } = useTwoFa(phone);
  const { selectedTwoFaMethod, qrData, isLoading } = twoFaState;
  const { phoneNumber, isChangePhoneNumber } = phoneState;
  const twoFaMethods: IPreference[] = useSelector((state: AppState) => state.common.twoFaMethods);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getTwoFaPreferences());
  }, [dispatch]);

  const steps = [
    {
      title: 'Two Factor Overview',
      content: <OverView />,
    },
    {
      title: 'Choose Method',
      content: (
        <Choose2FaMethod
          selectedTwoFaMethod={selectedTwoFaMethod}
          handleTwoFaMethodChange={handleTwoFaMethodChange}
          phoneNumber={phoneNumber}
          isChangePhoneNumber={isChangePhoneNumber}
          handleChangePhoneNumber={handleChangePhoneNumber}
          handleIsChangePhoneNumber={handleIsChangePhoneNumber}
          twoFaMethods={twoFaMethods}
        />
      ),
    },
    {
      title: 'Link Your Account',
      content: <LinkAccount selectedTwoFaMethod={selectedTwoFaMethod} qrData={qrData} />,
    },
  ];
  const next = () => {
    // Triggered when Next Button is clicked on second step (i.e Choose Method Step)
    if (current === 1) {
      dispatch(
        enrollService({
          selectedTwoFaMethod,
          handleQRData,
          setCurrent,
          current,
          handle2FaIsLoading,
          phoneNumber,
          twoFaMethods,
        })
      );
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: 20,
  };
  const containerStyle: React.CSSProperties = {
    width: '90%',
    margin: 20,
  };
  return (
    <div style={containerStyle}>
      <CustomHelmet title="Two Factor Authentication Setup" />

      <Card title="BIP Clientcare">
        <Steps current={current} items={items} />
        <div style={contentStyle}>{steps[current].content}</div>
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{
              marginTop: 24,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                Previous Step
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={
                  (current === 1 && selectedTwoFaMethod === '') ||
                  (current === 1 && selectedTwoFaMethod !== '' && selectedTwoFaMethod === 'SMS'
                    ? phoneNumber === '' || phoneNumber === null
                    : false)
                }
              >
                Next Step
              </Button>
            )}
            {/* {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )} */}
          </div>
        )}
      </Card>
    </div>
  );
}

export default TwoFactorSetUp;
