/* eslint-disable @typescript-eslint/no-explicit-any */
import { Table as AntdTable, Typography } from 'antd';
import { dollarCurrencyFormat } from '@/utils/helper';
import { ColumnsType } from 'antd/lib/table';

interface ISumFields {
  field: string;
  displayFormat?: string;
  value: string;
  align?: 'left' | 'right' | 'center';
  decimal?: number;
}

interface IColumnsConfig extends ISumFields {
  position: number;
}

interface IFooter {
  columns: ColumnsType<any>;
  sumFields: ISumFields[];
  pageData: ColumnsType<any>;
  label: string;
}

function calculateTotals(pageData: any, sumFields: ISumFields[]) {
  return sumFields.reduce((totals: Record<string, number>, item: ISumFields) => {
    const total = parseFloat(
      pageData
        .filter(
          (crr: any) => !Number.isNaN(Number(crr[item.value])) && Number(crr[item.value]) !== 0
        )
        .reduce((acc: number, crr: any) => acc + Number(crr[item.value]), 0)
        .toFixed(item?.decimal ?? 2)
    );
    return {
      ...totals,
      [item.value]: total,
    };
  }, {});
}

function formatValue(value: number, displayFormat: string = '') {
  if (displayFormat === 'currency') {
    return value && !Number.isNaN(value) ? dollarCurrencyFormat(value) : '$0.00';
  }
  if (displayFormat === 'percent') {
    return value && !Number.isNaN(value) ? `${value}%` : '0.00%';
  }

  return value && !Number.isNaN(value) ? value : '0.00';
}
// TODO: Refactor this approach to calculate colspans for missing footer columns
function calculateColSpans(sumFields: IColumnsConfig[], totalColumns: number) {
  const sortedsumFields = sumFields
    .slice()
    .sort((a: IColumnsConfig, b: IColumnsConfig) => a.position - b.position);
  const colSpans = [];
  let currentPosition = 0;

  for (let i = 0; i < sortedsumFields.length; i++) {
    const column = sortedsumFields[i];
    const nextPosition =
      i + 1 < sortedsumFields.length ? sortedsumFields[i + 1].position : currentPosition;

    let colspan = nextPosition - column.position;

    if (i === sortedsumFields.length - 1 && colspan === 0) {
      colspan = totalColumns - column.position;
    }

    colSpans.push({ field: sortedsumFields[i].field, colspan });
    currentPosition = nextPosition;
  }

  return colSpans;
}

function Footer({ pageData, sumFields, columns, label }: IFooter) {
  const validSumFields = sumFields
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((item: ISumFields) => ({
      ...item,
      position: columns.findIndex((col: any) => col.dataIndex === item.field),
    }))
    .filter((data: IColumnsConfig) => data?.position >= 0);
  // Calculate the totals based on the provided keys
  const totals = calculateTotals(pageData, validSumFields);

  // Calculate colspans based on columns configuration
  const totalColumns = columns.length;
  const colSpans = calculateColSpans(validSumFields, totalColumns);

  const sortedSumFields = validSumFields
    .slice()
    .sort((a: IColumnsConfig, b: IColumnsConfig) => a.position - b.position);

  return (
    <AntdTable.Summary.Row>
      {label && (
        <AntdTable.Summary.Cell
          colSpan={sortedSumFields[0]?.position === 0 ? 0 : sortedSumFields[0]?.position}
          index={0}
        >
          <Typography.Text strong>{label}</Typography.Text>
        </AntdTable.Summary.Cell>
      )}
      {sortedSumFields.map((item: IColumnsConfig, index: number) => (
        <AntdTable.Summary.Cell
          index={index}
          key={item.field}
          colSpan={colSpans.find((colSpan) => colSpan.field === item.field)?.colspan}
          align={item.align}
        >
          <Typography.Text strong>
            {item.value ? formatValue(totals[item.value], item.displayFormat) : ''}
          </Typography.Text>
        </AntdTable.Summary.Cell>
      ))}
    </AntdTable.Summary.Row>
  );
}

export default Footer;
