import { Col, Row } from 'antd';
import Card from '@/components/Card';
import Table from '@/components/Table';
import Footer from '@/components/Table/Footer';

interface ITransactionDetails {
  data: {
    transaction_id: number;
    transaction_date_raw: string;
    transaction_date_display: string;
    description: string;
    amount_raw: number;
    amount_display: string;
  }[];
  isLoading: boolean;
  title: 'Capital Calls' | 'Distributions';
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderFooter(data: any, columns: any): any {
  return (
    <Footer
      pageData={data}
      label="Total"
      sumFields={[{ field: 'amount_display', displayFormat: 'currency', value: 'amount_raw' }]}
      columns={columns}
    />
  );
}
function TransactionDetails(props: ITransactionDetails) {
  const { data, isLoading, title } = props;
  const columns = [
    {
      title: 'Date',
      dataIndex: 'transaction_date_display',
      key: 'transaction_date_display',
    },
    {
      title: 'Description',
      dataIndex: 'title',
      key: 'title',
      // width: '100%',
    },
    {
      title: title === 'Capital Calls' ? 'Amount Due' : 'Amount',
      dataIndex: 'amount_display',
      key: 'amount_display',
      width: 100,
    },
  ];

  return (
    <Card title={title}>
      <Row gutter={16}>
        <Col span={24}>
          <Table
            className="bg-header-white"
            isCardLayout={false}
            dataSource={data}
            columns={columns}
            globalSearch={false}
            summary={(pageData) => data.length > 0 && renderFooter(pageData, columns)}
            isLoading={isLoading}
            rowKey={(record) => record?.transaction_id}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default TransactionDetails;
